/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import './src/styles/tailwind.base.css'

import './src/styles/base.css'

import './src/styles/tailwind.components.css'

import './src/styles/components.css'

import './src/styles/tailwind.utilities.css'

import './src/styles/utilities.css'
